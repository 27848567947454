.hidden-textfield {
  display: none;
}

#html5-qrcode-button-camera-permission {
  background-color: #17a2b8;
  color: #fff;
  border: 1px solid #17a2b8;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

#html5-qrcode-button-camera-permission:hover {
  background-color: #138496;
  border-color: #138496;
}

#html5-qrcode-button-camera-stop {
  background-color: #dc3545;
  color: #fff;
  border: 1px solid #dc3545;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

#html5-qrcode-button-camera-stop:hover {
  background-color: #c82333;
  border-color: #c82333;
}

#html5-qrcode-button-camera-start {
  background-color: #28a745;
  color: #fff;
  border: 1px solid #28a745;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

#html5-qrcode-button-camera-start:hover {
  background-color: #218838;
  border-color: #218838;
}

hr {
  margin: 0 !important;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

.main {
  padding: 10px;
}

.normodal {
  position: fixed;
  top: 10px;
  left: 0;
  z-index: 1055;
  /* padding: 10px; */
  width: 100%;
  /* height: 100%; */
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-content {
  padding: 10px;
}

.modal-header {
  padding: 10px;
}

.modal-body {
  padding-left: 10px;
  color: #2000ff;
}

.modal-footer {
  padding: 10px;
}

.modal-footer > * {
  margin: 3px !important;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px) !important;
  padding-bottom: 0 !important;
  /* margin-bottom: 5px !important; */
  font-size: inherit;
  padding-right: 0px !important;
  line-height: 1.5;
}

.chart-container {
  height: 800px;
}

.printpage {
  background: white;
  display: block;
  margin: 0 auto;
  position: relative;
  box-shadow: #444444;
  font-size: smaller;
  width: 210mm;
  height: 297mm;
  padding: 5mm;
  /* padding-left: 6mm;
  padding-right: 6mm; */
}
.memopage {
  background: white;
  display: block;
  margin: 0 auto;
  position: relative;
  box-shadow: #444444;
  font-size: smaller;
  width: 210mm;
  /* padding: 4mm; */
  page-break-before: always;
  /* padding-left: 6mm;
  padding-right: 6mm; */
}
.tablepage {
  background: white;
  display: block;
  margin: 0 auto;
  position: relative;
  box-shadow: #444444;
  font-size: smaller;
  width: 297mm;
  /* padding: 4mm; */
  page-break-before: always;
  /* padding-left: 6mm;
  padding-right: 6mm; */
}

/* @media print 
{
   @page
   {
    size: A5;
    size: landscape;
  }
} */

.printpage[size='A4'] {
  width: 210mm;
  height: 296mm;
  overflow: hidden;
}

.printpage[size='A5'] {
  width: 14.8cm;
  height: 21cm;
  overflow: hidden;
}

@media print {
  @page {
    size: A4;
    size: portrait;
  }
}

.biltypage {
  width: 200mm;
  height: 142mm;
  border: 1px solid black;
  border-radius: 8px;
  /* margin-bottom: 12px; */
  overflow: hidden;
}

.biltypage table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
.biltypage th,
.biltypage td {
  border: 1px solid black;
  height: 20px;
}
.biltyTrack {
  border: 1px solid black;
  border-radius: 8px;
  /* margin-bottom: 12px; */
  overflow: hidden;
}

.biltyTrack table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
.biltyTrack th,
.biltyTrack td {
  border: 1px solid black;
  height: 20px;
}

.biltymiddle td {
  height: 20px;
  /* border: none; */
}

.biltyhead td,
.biltyhead th {
  border: none;
  text-align: center;
}

.column {
  width: 65%;
  float: left;
  box-sizing: border-box;
}

.container:after {
  content: '';
  display: table;
  clear: both;
}

/* @media print {
  .printable-area {
    display: block;
  }
} */

.biltydevider:last-child {
  display: none;
}

.num {
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.8em;
}

:root {
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: #f6f9ff;
  color: #000;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
}

/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  /* padding: 15px; */
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: 'Poppins', sans-serif;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 10px 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav > ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 20px;
  color: #012970;
}

.header-nav .nav-profile {
  color: #012970;
}

.header-nav .nav-profile img {
  max-height: 36px;
  width: 31px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
  max-width: 120px;
}

.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.profile .profile-overview .card-title {
  color: #012970;
}

.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
  max-width: 120px;
}

.receipt-content .invoice-wrapper {
  background: #fff;
  border: 1px solid #080808;
  box-shadow: 0px 0px 1px #ccc;
  /* padding: 5mm; */
  /* margin-top: 10px;
  border-radius: 4px; */
}

.receipt-content {
  background: #ffffff;
}
/* @media (min-width: 1200px) {
  .receipt-content .container {
    width: 900px;
  }
} */

.receipt-content .invoice-wrapper .line-items {
  margin-top: 5px;
}

.receipt-content .invoice-wrapper .line-items .total {
  margin-top: 5px;
}

.field {
  margin-bottom: 7px;
  font-size: 14px;
  color: #555;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.receipt-content .invoice-wrapper .line-items .total .grand-total {
  margin-top: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #555;
  display: flex;
  justify-content: space-between;
}

.receipt-content .invoice-wrapper .line-items .total .grand-total span {
  color: #20a720;
  display: inline-block;
  /* margin-left: 20px; */
  min-width: 85px;
  font-size: 16px;
  text-align: right;
}

.receipt-content .invoice-wrapper .line-items .total .field span {
  display: inline-block;
  /* margin-left: 20px; */
  min-width: 85px;
  color: #84868a;
  font-size: 15px;
  text-align: right;
}

.memo-table {
  width: 100%;
}

.memo-table table {
  width: 100%;
  border-collapse: collapse;
}

.memo-table table th {
  padding: 3px;
  text-align: center;
  word-wrap: break-word;
  border: 1px solid;
}
.memo-table table td {
  padding: 2px;
  text-align: center;
  word-wrap: break-word;
  border: 1px solid;
}

.memo-table table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.memo-table table tbody td {
  background-color: #ffffff;
  border: 1px solid !important;
}

.memofooter table {
  width: 100%;
  /* border-collapse: collapse; */
}

.memofooter table tr {
  height: 20px;
}

.memofooter table tr td {
  padding: 0px;
  text-align: center;
  word-wrap: break-word;
  border: 1px solid;
}

/* .MuiAutocomplete-option.Mui-focused {
  background-color: #002fffb5 !important;
  color: #fff !important;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
  color: #000 !important;
} */
